export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(authenticated)/(root)": [~8,[2]],
		"/(authenticated)/contacts": [~5,[2]],
		"/(unauthenticated)/login/(root)": [~12,[3]],
		"/(unauthenticated)/login/change": [~10,[3]],
		"/(unauthenticated)/login/recover": [~11,[3]],
		"/(unauthenticated)/login/[slug]": [~13,[3]],
		"/(authenticated)/property/[reso_id]": [~6,[2]],
		"/(authenticated)/public-records": [~4,[2]],
		"/(authenticated)/settings": [~7,[2]],
		"/(authenticated)/[slug]": [~9,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';